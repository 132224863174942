import CryptoJS from 'crypto-js'

export const convertToCountryFormat = (region, showCurrencySymbol, currencySymbolType, price) => {
  if (price != null && typeof price != 'undefined') {
    // return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    let formatOptions = {
      maximumFractionDigits: 2,
    }
    if (showCurrencySymbol) {
      formatOptions = {
        ...formatOptions,
        style: 'currency',
        currency: currencySymbolType,
      }
    }
    return Number(price).toLocaleString(region, formatOptions)
  }
  return price
}

export const encryptText = (text) => {
  if (text == null || typeof text == 'undefined') {
    return ''
  }
  return CryptoJS.AES.encrypt(String(text).toString(), process.env.REACT_APP_SECRET_KEY).toString()
}

export const decryptText = (encryptedText) => {
  console.log('Hash', process.env.REACT_APP_SECRET_KEY)
  if (encryptedText == null || typeof encryptedText == 'undefined') {
    return ''
  }
  const bytes = CryptoJS.AES.decrypt(
    String(encryptedText).toString(),
    process.env.REACT_APP_SECRET_KEY,
  )
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const validateGSTNo = (number) => {
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  let isValid = true

  if (!number.match(gstRegex)) {
    isValid = false
  }

  return isValid
}
