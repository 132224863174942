import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  showLoader: false,
  showFullScreenLoader: false,
  sidebarUnfoldable: false,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSidebarStatus(state, action) {
      state.sidebarShow = action.payload
    },
    setSidebarUnfoldableStatus(state, action) {
      state.sidebarUnfoldable = action.payload
    },

    setLoaderStatus(state, action) {
      state.showLoader = action.payload.showLoader
      state.showFullScreenLoader = action.payload.showFullScreenLoader
    },
  },
})

export const { setLoaderStatus, setSidebarStatus, setSidebarUnfoldableStatus } =
  dashboardSlice.actions

export default dashboardSlice.reducer
