import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as momentTz from 'moment-timezone'

export default function PackingSlip() {
  let [searchParams, setSearchParams] = useSearchParams()
  let packingSlipData = {}

  if (searchParams.has('packingSlipData')) {
    packingSlipData = JSON.parse(searchParams.get('packingSlipData'))
    console.log('Packingslip', JSON.parse(searchParams.get('packingSlipData')))
  }

  useEffect(() => {
    document.title =
      `Packing_slip_${packingSlipData?.name ?? ''}_${packingSlipData?.wbn ?? ''}` ??
      'Toddlers-And-Tots-Packing-Slip'

    window.print()
  }, [])

  return (
    <div className="vw-100 vh-100 d-flex flex-row justify-content-center align-items-center">
      <div
        style={{
          width: 400,
          borderStyle: 'solid',
          borderColor: 'black',
          borderLeftWidth: 2,
          borderTopWidth: 2,
          borderBottomWidth: 2,
          borderRightWidth: 2,
        }}
      >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderStyle: 'solid',
              borderColor: 'black',
              borderLeftWidth: 0,
              borderTopWidth: 0,
              borderBottomWidth: 0,
              borderRightWidth: 2,
            }}
          >
            <span style={{ fontSize: 18, fontWeight: 'bold', lineHeight: 1.3 }}>
              {packingSlipData?.snm}
            </span>
          </div>
          <div
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={packingSlipData?.delhivery_logo} style={{ width: 125 }} />
          </div>
        </div>

        <div
          style={{
            width: '100%',
            borderStyle: 'solid',
            borderColor: 'black',
            borderLeftWidth: 0,
            borderTopWidth: 2,
            borderBottomWidth: 2,
            borderRightWidth: 0,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <img
              src={String(packingSlipData?.barcode ?? '').replace(' ', '')}
              style={{ width: 210, height: 75 }}
            />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%', paddingLeft: 5 }}>{packingSlipData?.pin}</div>
            <div style={{ width: '50%', paddingRight: 5 }}>
              <span style={{ fontSize: 18, fontWeight: 'bold', float: 'right' }}>
                {packingSlipData?.sort_code}
              </span>
            </div>
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              width: '70%',
              borderStyle: 'solid',
              borderColor: 'black',
              borderLeftWidth: 0,
              borderTopWidth: 0,
              borderBottomWidth: 0,
              borderRightWidth: 2,
              paddingLeft: 5,
            }}
            className="d-flex flex-column"
          >
            <span style={{ fontWeight: 'bold' }}>Ship To:</span>
            <span style={{ lineHeight: 1, fontSize: 17, fontWeight: 'bold' }}>
              {String(packingSlipData?.name ?? '').toUpperCase()}
            </span>
            <span style={{ lineHeight: 1.4, marginTop: 5 }}>{packingSlipData?.name}</span>
            <span style={{ lineHeight: 1.4 }}>{packingSlipData?.address}</span>
            <span style={{ lineHeight: 1.4 }}>{packingSlipData?.destination}</span>
            <span style={{ fontWeight: 'bold', lineHeight: 1.4 }}>PIN:{packingSlipData?.pin}</span>
          </div>
          <div
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              <div>
                {packingSlipData?.pt}
                <br />
                Surface
              </div>
              <div style={{ marginTop: 5 }} className="d-flex flex-column">
                <span style={{ lineHeight: 1.4 }}>INR</span>

                <span style={{ lineHeight: 1.4 }}>{packingSlipData?.rs}</span>
              </div>
            </span>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            borderStyle: 'solid',
            borderColor: 'black',
            borderLeftWidth: 0,
            borderTopWidth: 2,
            borderBottomWidth: 2,
            borderRightWidth: 0,
          }}
        >
          <div
            style={{
              width: '70%',
              borderStyle: 'solid',
              borderColor: 'black',
              borderLeftWidth: 0,
              borderTopWidth: 0,
              borderBottomWidth: 0,
              borderRightWidth: 2,
              paddingLeft: 5,
            }}
          >
            <span style={{ fontSize: 17, fontWeight: 'bold', lineHeight: 1.3 }}>Seller:</span>{' '}
            {packingSlipData?.snm}
            <br />
            <span style={{ lineHeight: 1.4, fontSize: 17, fontWeight: 'bold' }}>Address:</span>{' '}
            {packingSlipData?.sadd}
          </div>
          <div
            style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <span style={{ fontSize: 17, fontWeight: 'bold' }}>Date:</span>
            <span style={{ lineHeight: 1.3 }}>
              {momentTz(packingSlipData?.cd ?? new Date())
                .tz('asia/kolkata')
                .format('YYYY-MM-DD hh:mm:ss')}
            </span>
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <table style={{ width: '100%', marginBottom: 0 }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: '70%',
                    flexDirection: 'row',
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 2,
                    paddingLeft: 5,
                  }}
                >
                  <span style={{ fontSize: 17, fontWeight: 'bold' }}>Product(Qty)</span>
                </td>
                <td
                  style={{
                    width: '15%',
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 2,
                    textAlign: 'center',
                  }}
                >
                  <span style={{ fontSize: 17, fontWeight: 'bold' }}>Price</span>
                </td>
                <td
                  style={{
                    width: '15%',
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 0,
                    textAlign: 'center',
                  }}
                >
                  <span style={{ fontSize: 17, fontWeight: 'bold' }}>Total</span>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 2,
                    paddingLeft: 5,
                  }}
                >
                  <span style={{ lineHeight: 1.3 }}> {packingSlipData?.prd}</span>
                </td>
                <td
                  style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 2,
                    textAlign: 'center',
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ lineHeight: 1.4 }}>INR</span>
                    <span style={{ lineHeight: 1.4 }}>{packingSlipData?.rs}</span>
                  </div>
                </td>
                <td
                  style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 0,
                    textAlign: 'center',
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ lineHeight: 1.4 }}>INR</span>
                    <span style={{ lineHeight: 1.4 }}>{packingSlipData?.rs}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 2,
                    paddingLeft: 5,
                  }}
                >
                  Total
                </td>
                <td
                  style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 2,
                    textAlign: 'center',
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ lineHeight: 1.4 }}>INR</span>
                    <span style={{ lineHeight: 1.4 }}>{packingSlipData?.rs}</span>
                  </div>
                </td>
                <td
                  style={{
                    borderStyle: 'solid',
                    borderColor: 'black',
                    borderLeftWidth: 0,
                    borderTopWidth: 0,
                    borderBottomWidth: 2,
                    borderRightWidth: 0,
                    textAlign: 'center',
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ lineHeight: 1.4 }}>INR</span>
                    <span style={{ lineHeight: 1.4 }}>{packingSlipData?.rs}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: 'black',
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 2,
            borderRightWidth: 0,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <img
            src={String(packingSlipData?.oid_barcode ?? '').replace(' ', '')}
            style={{ width: 135, height: 65 }}
          />
        </div>
        <div style={{ width: '100%', paddingLeft: 5, paddingRight: 5 }}>
          <span style={{ lineHeight: 1.3 }}>
            <span style={{ fontSize: 17, fontWeight: 'bold' }}>Return Address:</span>{' '}
            {packingSlipData?.radd}
          </span>
        </div>
      </div>
    </div>
  )
}
