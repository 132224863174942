import _ from 'lodash'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCartData } from 'src/helpers/AxiosHelper'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const userData = {
  email: cookies.get('email'),
  firstName: cookies.get('firstName'),
  lastName: cookies.get('lastName'),
  mobile: cookies.get('mobile'),
  userId: cookies.get('userId'),
  userStatus: cookies.get('userStatus'),
  userType: cookies.get('userType'),
}

export const initialState = {
  cart: [],
  user: cookies.get('userId') ? userData : null,
  totalCoins: 0,
  useCoins: false,
  showSearchModal: false,
}

// helper functions
export const getCartSubTotal = (cart) =>
  cart?.reduce(
    (amount, item) =>
      (item?.price * (item?.gst / 100 ?? 1) + item?.price) * item?.cart_count + amount,
    0,
  )

export const getCartTotal = (cart) =>
  cart?.reduce(
    (amount, item) =>
      (item?.price * (item?.gst / 100 ?? 1) + item?.price) * item?.cart_count + amount,
    0,
  )

export const getTotalGST = (cart) =>
  cart?.reduce(
    (amount, item) => item?.price * (item?.gst / 100 ?? 1) * item?.cart_count + amount,
    0,
  )

export const getCoinToPriceValue = (totalCoins) => parseInt(totalCoins / 10000) * 100

export const getTotalDeliveryCharges = (cart) =>
  cart?.reduce((amount, item) => item?.delivery_charges + amount, 0)

// thunk helper
export const fetchCartItems = createAsyncThunk('customer/fetchCartItems', async () => {
  try {
    if (initialState?.user) {
      const response = await getCartData()
      console.log('Response123', response)
      return response.data.data
    }
    return []
  } catch (err) {
    return err
  }
})

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload
    },
    addItemToCart(state, action) {
      // logic for adding to cart
      sessionStorage.removeItem('last_order_data')
      let foundItemIndex = state.cart.findIndex((item) => item.id == action.item.id)
      let tempCart = []
      if (foundItemIndex != -1) {
        tempCart = _.cloneDeep(state.cart)
        tempCart[foundItemIndex]['count'] = tempCart[foundItemIndex]['count'] + 1
      } else {
        tempCart = [...state.cart, { ...action.payload, count: 1 }]
      }

      console.log('cart', tempCart)
      state.cart = tempCart
    },
    removeItemFromCart(state, action) {
      // logic for removing
      sessionStorage.removeItem('last_order_data')
      let newCart = [...state.cart] //cloning cart
      const index = state.cart.findIndex(
        (cartItem) => cartItem.id === action.id, // getting index of item to be deleted
      )

      if (index >= 0) {
        newCart.splice(index, 1) //remove 1 element after "index"
      } else {
        console.warn(`${action.id} does not exist `)
      }
      state.cart = newCart
    },
    clearCart(state, action) {
      sessionStorage.removeItem('last_order_data')
      state.cart = []
    },
    useCoins(state, action) {
      state.useCoins = action.payload
    },
    setCoins(state, action) {
      state.totalCoins = action.payload
    },
    changeSearchModalStatus(state, action) {
      state.showSearchModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchCartItems.pending, (state, action) => {
      //   state.status = 'loading'
      // })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.cart = action.payload
      })
    // .addCase(fetchCartItems.rejected, (state, action) => {
    //   state.cart = action.payload
    // })
  },
})

export const {
  setUser,
  addItemToCart,
  removeItemFromCart,
  clearCart,
  useCoins,
  setCoins,
  changeSearchModalStatus,
} = customerSlice.actions

export default customerSlice.reducer
