import React, { useEffect } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import qs from 'qs'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client'
import { data } from 'jquery'
import { decryptText } from './MiscHelper'

export const serverUrl = process.env.REACT_APP_SERVER_URL

const baseUrl = `${serverUrl}/api`
const graphqlBaseUrl = `${serverUrl}/graphql`

const cookies = new Cookies()

export const apolloClient = new ApolloClient({
  uri: graphqlBaseUrl,
  cache: new InMemoryCache(),
})

axios.interceptors.request.use(
  (config) => {
    console.log('cookies', cookies.getAll())
    if (cookies.get('authToken')) {
      config.headers['Authorization'] = 'Bearer ' + cookies.get('authToken')
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

// Countries
export function getAllCountries(page = 0, pageSize = 0) {
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/countries`
      : `${baseUrl}/countries?pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
  )
}

export function updateCountryData(id, data) {
  return axios.put(`${baseUrl}/countries/${id}`, data)
}

export function addCountryData(data) {
  return axios.post(`${baseUrl}/countries`, data)
}

// States
export function getAllStates(page = 0, pageSize = 0) {
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/states?populate=*`
      : `${baseUrl}/states?populate[country][populate]=%2A&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
  )
}

export function updateStateData(id, data) {
  return axios.put(`${baseUrl}/states/${id}`, data)
}

export function addStateData(data) {
  return axios.post(`${baseUrl}/states`, data)
}

// Cities
export function getAllCities(page = 0, pageSize = 0) {
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/cities?populate=*`
      : `${baseUrl}/cities?populate[state][populate]=%2A&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
  )
}

export function updateCityData(id, data) {
  return axios.put(`${baseUrl}/cities/${id}`, data)
}

export function addCityData(data) {
  return axios.post(`${baseUrl}/cities`, data)
}

// Category
export function getAllCategories(
  page = 0,
  pageSize = 0,
  filter = null,
  showOnlyActiveItems = true,
) {
  let condition = '',
    appliedQuery = {}
  if (filter != null) {
    appliedQuery = { filters: { $and: [] } }
    if (filter?.categoryStatus != 'ALL') {
      appliedQuery = { filters: { $and: [{ is_active: { $eq: filter?.categoryStatus } }] } }
    }
    if (filter?.subcategoryStatus != 'ALL') {
      appliedQuery = {
        filters: {
          $and: [
            ...appliedQuery.filters['$and'],
            { item_sub_categories: { is_active: { $eq: filter?.subcategoryStatus } } },
          ],
        },
      }
    }
    if (filter?.search.trim() != '') {
      // appliedQuery += `&filters[$or][0][name][$containsi]=${filter?.search}&filters[$or][1][code][$containsi]=${filter?.countryStatus}`

      appliedQuery = {
        filters: {
          $and: [
            ...appliedQuery.filters['$and'],
            {
              $or: [
                { name: { $containsi: filter?.search } },
                { item_sub_categories: { name: { $containsi: filter?.search } } },
              ],
            },
          ],
        },
      }
    }
  }

  condition = qs.stringify(
    {
      ...appliedQuery,
      populate: {
        item_sub_categories: {
          populate: '*',
        },
        image: {
          populate: '*',
        },
      },
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )

  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/item-categories?populate[item_sub_categories][populate]=%2A&populate[image][populate]=%2A${
          showOnlyActiveItems ? '&filters[is_active][$eq]=true' : ''
        }`
      : `${baseUrl}/item-categories?${condition}`,
  )
}

export function updateCategoryData(id, data) {
  return axios.put(`${baseUrl}/item-categories/${id}`, data)
}

export function addCategoryData(data) {
  console.log('Data555', data)
  return axios.post(`${baseUrl}/item-categories`, data)
}

export function uploadFile(data) {
  return axios.post(`${baseUrl}/upload`, data)
}

export function updateSubCategoryData(id, data) {
  return axios.put(`${baseUrl}/item-sub-categories/${id}`, data)
}

export function addSubCategoryData(data) {
  return axios.post(`${baseUrl}/item-sub-categories/add-new-subcategory`, data)
}

//Auth
export function sendOTPForLogin(data) {
  return axios.post(`${baseUrl}/users-permissions/send-otp-for-login`, data)
}

export function sendOTP(data) {
  return axios.post(`${baseUrl}/user-otps/send-otp`, data)
}

export function verifyOTP(data) {
  return axios.post(`${baseUrl}/user-otps/verify-otp`, data)
}

export function verifyOTPAndLogin(data) {
  return axios.post(`${baseUrl}/user-otps/verify-otp-and-login`, data)
}

export function handleSocialLogin(data) {
  return axios.post(`${baseUrl}/users-permissions/verify-user`, data)
}

// Users
export function getAllUsers(page = 0, pageSize = 0, filter) {
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/users-permissions/all-users?filter=${JSON.stringify(filter)}`
      : `${baseUrl}/users-permissions/all-users?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filter=${JSON.stringify(
          filter,
        )}`,
  )
}

export function updateUserData(id, data) {
  return axios.put(`${baseUrl}/users/${id}`, data)
}

export function sendMail(data) {
  return axios.post(`${baseUrl}/send-email`, data)
}

export function getUserRoles() {
  const query = qs.stringify(
    {
      filters: {
        $or: [{ name: 'Vendor' }, { name: 'Customer' }, { name: 'Sub-Admin' }],
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  console.log('query', query)
  return axios.get(`${baseUrl}/user-types?${query}`)
}

export function getColors() {
  return axios.get(`${baseUrl}/colors`)
}

export function getSizes() {
  return axios.get(`${baseUrl}/sizes`)
}

export function addNewProduct(data) {
  return axios.post(`${baseUrl}/items/add-product`, data)
}

export function updateProduct(data) {
  return axios.post(`${baseUrl}/items/update-product`, data)
}

export function deleteProduct(id) {
  return axios.get(`${baseUrl}/items/delete-product?productId=${id}`)
}

export function registerUser(data) {
  return axios.post(`${baseUrl}/users-permissions/register-user`, data)
}

export function updateUser(data) {
  return axios.post(`${baseUrl}/users-permissions/update-user`, data)
}

export function uploadUserDocuments(data) {
  return axios.post(`${baseUrl}/user-documents`, data)
}

export function fetchAllProducts(page = 0, pageSize = 0, filter = {}) {
  console.log('filter', filter)

  const query = qs.stringify(
    {
      ...filter,
      populate: {
        item_variations: {
          populate: '*',
        },
        user: {
          populate: {
            brand: {
              populate: '*',
            },
          },
        },
        item_category: {
          populate: '*',
        },
        item_sub_category: {
          populate: '*',
        },
      },
      pagination: {
        page,
        pageSize,
      },
      orderBy: { createdAt: 'desc' },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  console.log('query', query)
  return axios.get(
    page == 0 && pageSize == 0 ? `${baseUrl}/items?populate=*` : `${baseUrl}/items?${query}`,
  )
}

export function fetchProductDetails(id) {
  return axios.get(`${baseUrl}/items/item-detail?productId=${id}`)
}

export function updateProductData(id, data) {
  return axios.put(`${baseUrl}/items/${id}?populate=*`, data)
}

export function updateProductVariationData(id, data) {
  return axios.put(`${baseUrl}/item-variations/${id}`, data)
}

export function fetchUserData(userId = 0) {
  return axios.get(`${baseUrl}/users-permissions/get-user-details?userId=${userId}`)
}

export function fetchAllOrders(page = 0, pageSize = 0, filter) {
  console.log('filter', filter)

  const query = qs.stringify(
    {
      ...filter,
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  console.log('query', query)
  return axios.get(`${baseUrl}/orders/fetch-all-orders?${query}`)
}

export function fetchUserOrders(page = 0, pageSize = 0, filter) {
  console.log('filter', filter)

  const query = qs.stringify(
    {
      ...filter,
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  console.log('query', query)
  return axios.get(`${baseUrl}/orders/fetch-user-orders?${query}`)
}

export function fetchOrderDetail(id) {
  const query = qs.stringify(
    {
      populate: {
        user: '*',
        user_address: {
          populate: {
            state: '*',
            country: '*',
            city: '*',
          },
        },
        payment_status: '*',
        payment_detail: {
          populate: {
            payment_mode: '*',
            applied_coupon: '*',
          },
        },
        order_status: '*',
        order_detail: {
          populate: {
            item_variation: {
              populate: {
                item_variation_images: {
                  populate: {
                    item_image: '*',
                  },
                },
                item: {
                  populate: {
                    user: {
                      populate: {
                        brand: '*',
                      },
                    },
                    warehouse_address: {
                      populate: {
                        state: '*',
                        country: '*',
                        city: '*',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        return_order: {
          populate: {
            return_reason: '*',
            replacement_item_variation: {
              populate: {
                item_variation_images: {
                  populate: {
                    item_image: '*',
                  },
                },
                item: '*',
              },
            },
            delivery_charge_payment_status: '*',
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  console.log('query', query)
  return axios.get(`${baseUrl}/orders/${id}?${query}`)
}

export function approveOrder(data) {
  return axios.post(`${baseUrl}/orders/approve-order`, data)
}

export function rejectOrder(data) {
  return axios.post(`${baseUrl}/orders/reject-order`, data)
}

export function createOrder(data) {
  return axios.post(`${baseUrl}/orders/create-order`, data)
}

export function trackOrder(data) {
  return axios.post(`${baseUrl}/orders/track-order`, data)
}

export function cancelOrder(data) {
  return axios.post(`${baseUrl}/orders/cancel-order`, data)
}

export function returnItem(data) {
  return axios.post(`${baseUrl}/orders/return-item`, data)
}

export function rejectReturnRequest(data) {
  return axios.post(`${baseUrl}/orders/reject-return-request`, data)
}

export function initiatePickup(data) {
  return axios.post(`${baseUrl}/orders/initiate-pickup`, data)
}

export function initiateReturn(data) {
  return axios.post(`${baseUrl}/orders/initiate-return`, data)
}

export function initiateRefund(data) {
  return axios.post(`${baseUrl}/orders/initiate-refund`, data)
}

export function schedulePickup(data) {
  return axios.post(`${baseUrl}/orders/schedule-pickup`, data)
}

export function processRefund(data) {
  return axios.post(`${baseUrl}/orders/process-refund`, data)
}

export function createReturnRazorpayRequest(data) {
  return axios.post(`${baseUrl}/orders/create-return-razorpay-request`, data)
}

export function getBrands() {
  return axios.get(`${baseUrl}/brands`)
}

export function getOrderStatus() {
  return axios.get(`${baseUrl}/order-statuses`)
}

export function getPaymentStatus() {
  return axios.get(`${baseUrl}/payment-statuses`)
}

export function getPaymentModes() {
  return axios.get(`${baseUrl}/payment-modes`)
}

export function getGSTValues() {
  return axios.get(`${baseUrl}/gst-values`)
}

export function getReturnReasons() {
  return axios.get(`${baseUrl}/return-reasons`)
}

// Colors
export function getVendorSpecificColors(
  userId,
  applyIsActiveFilter = false,
  page = 0,
  pageSize = 0,
) {
  const pagination = {
    page,
    pageSize,
  }
  let appliedQuery = {
    filters: {
      user: {
        id: {
          $eq: userId,
        },
      },
    },

    orderBy: { name: 'asc' },
  }

  if (page != 0 && pageSize != 0) {
    appliedQuery['pagination'] = pagination
  } else {
    if (applyIsActiveFilter) {
      appliedQuery['filters']['is_active'] = true
    }
  }
  const query = qs.stringify(appliedQuery, {
    encodeValuesOnly: true, // prettify URL
  })
  console.log('query', query)
  return axios.get(`${baseUrl}/colors?${query}`)
}

export function updateColor(id, data) {
  return axios.put(`${baseUrl}/colors/${id}`, data)
}

export function addColor(data) {
  return axios.post(`${baseUrl}/colors`, data)
}

// Sizes
export function getVendorSpecificSizes(
  userId,
  applyIsActiveFilter = false,
  page = 0,
  pageSize = 0,
) {
  const pagination = {
    page,
    pageSize,
  }
  let appliedQuery = {
    filters: {
      user: {
        id: {
          $eq: userId,
        },
      },
    },

    orderBy: { name: 'asc' },
  }

  if (page != 0 && pageSize != 0) {
    appliedQuery['pagination'] = pagination
  } else {
    if (applyIsActiveFilter) {
      appliedQuery['filters']['is_active'] = true
    }
  }
  const query = qs.stringify(appliedQuery, {
    encodeValuesOnly: true, // prettify URL
  })
  console.log('query', query)
  return axios.get(`${baseUrl}/sizes?${query}`)
}

export function updateSize(id, data) {
  return axios.put(`${baseUrl}/sizes/${id}`, data)
}

export function addSize(data) {
  return axios.post(`${baseUrl}/sizes`, data)
}

export function getDashboardData(data) {
  return axios.post(`${baseUrl}/users-permissions/get-dashboard-data`, data)
}

export function getAllProducts(page = 0, pageSize = 0, filter = {}) {
  // return axios.get(`${baseUrl}/items/get-all-items`)
  const query = qs.stringify(
    {
      ...filter,
      populate: {
        item: {
          populate: ['user.brand', 'item_category', 'item_sub_category', 'gst'],
        },
        item_variation_images: {
          populate: '*',
        },
        order_details: {
          populate: '*',
        },
      },
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  console.log('query1234', filter, query)
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/item-variations?populate=*`
      : `${baseUrl}/item-variations?${query}`,
  )
}

export function getCategoryBasedProducts(categoryId) {
  const query = qs.stringify(
    {
      filters: {
        item: {
          item_category: {
            id: {
              $eq: categoryId,
            },
          },
        },
      },
      populate: {
        item: {
          populate: [
            'user.brand',
            'item_category',
            'item_sub_category',
            'gst',
            'item_variations.item',
            'item_variations.item_variation_images.item_image',
          ],
        },
        item_variation_images: {
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  return axios.get(`${baseUrl}/item-variations?${query}`)
}

export function getSingleProduct(id) {
  const query = qs.stringify(
    {
      filters: {
        slug: id,
      },
      populate: {
        item: {
          populate: [
            'user.brand',
            'item_category',
            'item_sub_category',
            'size_chart_image',
            'gst',
            'item_variations.item',
            'item_variations.item.gst',
            'item_variations.color',
            'item_variations.size',
            'item_variations.item_variation_images.item_image',
          ],
        },
        item_variation_images: {
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  return axios.get(`${baseUrl}/item-variations?${query}`)
}

export function getCartData() {
  return axios.get(`${baseUrl}/get-cart-data`)
}

export function addToCart(data) {
  return axios.post(`${baseUrl}/add-to-cart`, data)
}

export function updateCart(id, data) {
  return axios.put(`${baseUrl}/cart-details/${id}`, data)
}

export function deleteCart(id) {
  return axios.delete(`${baseUrl}/cart-details/${id}`)
}

export function getCartDataForGuest(data) {
  return axios.post(`${baseUrl}/get-cart-data-for-guest`, data)
}

export function getWishlistData(data) {
  return axios.post(`${baseUrl}/get-wishlist-data`, data)
}

export function addToWishlist(data) {
  return axios.post(`${baseUrl}/add-to-wishlist`, data)
}

export function deleteWishlist(id) {
  return axios.delete(`${baseUrl}/wishlists/${id}`)
}

export function placeOrder(data) {
  return axios.post(`${baseUrl}/orders/place-order`, data)
}

export function deleteOrder(data) {
  return axios.post(`${baseUrl}/orders/delete-order`, data)
}

export function confirmPayment(data) {
  return axios.post(`${baseUrl}/orders/confirm-payment`, data)
}

export function getPackingSlipData(data) {
  return axios.post(`${baseUrl}/orders/get-packing-slip-data`, data)
}

export function getCurrentUserData() {
  return axios.get(`${baseUrl}/users/me`)
}

export function addUserAddress(data) {
  return axios.post(`${baseUrl}/add-user-address`, data)
}

export function checkUserAddress(data) {
  return axios.post(`${baseUrl}/check-user-address`, data)
}

export function updateUserAddress(data) {
  return axios.post(`${baseUrl}/update-user-address`, data)
}

export function getSingleUserAddress(id) {
  return axios.get(`${baseUrl}/user-addresses/${id}?populate=*`)
}

export function getAllUserAddresses(
  page = 0,
  pageSize = 0,
  userId = null,
  fetchAllVendorAddresses = false,
  addressType = 'OTHER',
) {
  let appliedFilters = {
    populate: {
      city: '*',
      country: '*',
      state: '*',
      user: {
        populate: {
          brand: '*',
        },
      },
    },
  }
  if (fetchAllVendorAddresses) {
    appliedFilters = {
      ...appliedFilters,
      filters: {
        user: {
          user_type: {
            type: 'vendor',
          },
        },
      },
    }
  } else {
    appliedFilters = {
      ...appliedFilters,
      filters: {
        user: {
          id: userId ?? decryptText(cookies.get('userId')),
        },
      },
    }
  }

  if (addressType == 'WAREHOUSE') {
    appliedFilters['filters'] = {
      ...appliedFilters['filters'],
      address_type: {
        $eq: 'WAREHOUSE',
      },
    }
  } else {
    appliedFilters['filters'] = {
      ...appliedFilters['filters'],
      address_type: {
        $ne: 'WAREHOUSE',
      },
    }
  }

  const query = qs.stringify(appliedFilters, {
    encodeValuesOnly: true, // prettify URL
  })
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/user-addresses?${query}`
      : `${baseUrl}/user-addresses?${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
  )
}

export function getAllBanners(page = 0, pageSize = 0, filter = {}) {
  const query = qs.stringify(
    {
      ...filter,
      populate: {
        item_variation: {
          populate: {
            item: {
              populate: '*',
            },
          },
        },
        item_category: {
          populate: '*',
        },
        image: {
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/banners?${query}`
      : `${baseUrl}/banners?${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
  )
}

export function updateBanner(data) {
  return axios.post(`${baseUrl}/banner/update-banner`, data)
}

export function changeBannerStatus(id, data) {
  return axios.put(`${baseUrl}/banners/${id}`, data)
}

export function addBanner(data) {
  return axios.post(`${baseUrl}/banner/add-banner`, data)
}

export function fetchTnTHistory(page = 0, pageSize = 0) {
  const query = qs.stringify(
    {
      filters: {
        user: {
          id: {
            $eq: decryptText(cookies.get('userId')),
          },
        },
      },
      pagination: {
        page,
        pageSize,
      },
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  )
  console.log('query', query)
  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/coin-histories?populate=*`
      : `${baseUrl}/coin-histories?${query}`,
  )
}

export function getNewProducts() {
  return axios.get(`${baseUrl}/item-variation/get-new-products`)
}

export function addComment(data) {
  return axios.post(`${baseUrl}/comments/add-comment`, data)
}

export function getAllProductComments(page = 0, pageSize = 0, productId = 0) {
  const productSpecificCommentsQuery = {
    populate: {
      user: { populate: true },
      item_variation: { populate: true },
    },
    filters: {
      $and: [
        {
          item_variation: {
            slug: {
              $eq: productId,
            },
          },
        },
        {
          status: {
            $eq: 'ACCEPTED',
          },
        },
      ],
    },
    sort: ['createdAt:desc'],
  }

  const allCommentsQuery = {
    populate: {
      user: { populate: true },
      item_variation: {
        populate: {
          item: { populate: true },
        },
      },
    },
    sort: ['createdAt:desc'],
  }

  const query = qs.stringify(productId == 0 ? allCommentsQuery : productSpecificCommentsQuery, {
    encodeValuesOnly: true, // prettify URL
  })

  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/comments?${query}`
      : `${baseUrl}/comments?${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
  )
}

export function updateComment(data) {
  return axios.post(`${baseUrl}/comments/update-comment`, data)
}

export function getAllCoupons() {
  return axios.get(`${baseUrl}/coupons`)
}

export function buyCoupon(data) {
  return axios.post(`${baseUrl}/user-coupon/send-coupon-to-user`, data)
}

export function checkCouponAvailability(data) {
  return axios.post(`${baseUrl}/user-coupon/check-coupon-validity`, data)
}

// Commissions
export function getCommissions(page = 0, pageSize = 0, filter = null) {
  let appliedQuery = {
    populate: {
      user: {
        populate: {
          brand: '*',
        },
      },
    },
  }
  if (filter != null) {
    appliedQuery['filters'] = {
      month: `${filter?.month}-${filter?.year}`,
    }
    if (filter?.status != '') {
      appliedQuery['filters']['payment_status'] = filter?.status
    }
  }

  if (page != 0 && pageSize != 0) {
    appliedQuery['pagination'] = {
      page,
      pageSize,
    }
  }

  const query = qs.stringify(appliedQuery, {
    encodeValuesOnly: true, // prettify URL
  })

  return axios.get(`${baseUrl}/commissions?${query}`)
}

export function getVendorEarnings(page = 0, pageSize = 0, filter = null) {
  let appliedQuery = {}
  if (filter != null) {
    appliedQuery = {
      ...appliedQuery,
      year: filter?.year,
    }
    if (filter?.status != '') {
      appliedQuery = {
        ...appliedQuery,
        payment_status: filter?.status,
      }
    }
  }

  if (page != 0 && pageSize != 0) {
    appliedQuery = {
      ...appliedQuery,
      page,
      pageSize,
    }
  }

  const query = qs.stringify(appliedQuery, {
    encodeValuesOnly: true, // prettify URL
  })

  return axios.get(`${baseUrl}/get-vendor-earnings?${query}`)
}

export function updateCommission(id, data) {
  return axios.put(`${baseUrl}/commissions/${id}`, data)
}

export function addTestimonialData(data) {
  return axios.post(`${baseUrl}/testimonials`, data)
}

export function getAllTestimonials(page = 0, pageSize = 0, filters = {}) {
  const testimonialQuery = {
    ...filters,
    populate: {
      user: { populate: true },
    },
    sort: ['createdAt:desc'],
  }

  const query = qs.stringify(testimonialQuery, {
    encodeValuesOnly: true, // prettify URL
  })

  return axios.get(
    page == 0 && pageSize == 0
      ? `${baseUrl}/testimonials?${query}`
      : `${baseUrl}/testimonials?${query}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
  )
}

export function updateTestimonial(id, data) {
  return axios.put(`${baseUrl}/testimonials/${id}`, data)
}

// Graphql endpoints
export function getTopProducts() {
  return gql`
    query {
      customItemVariation(filter: { sort: "desc", limit: 10 }) {
        count
        item_variation {
          id
          code
          price
          inventory_count
          rating
          slug
          item {
            data {
              id
              attributes {
                name
                description
                gst {
                  data {
                    id
                    attributes {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
          item_variation_images {
            data {
              id
              attributes {
                item_image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}
