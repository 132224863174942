import { configureStore } from '@reduxjs/toolkit'
import dashboardSlice from './features/dashboard/dashboardSlice'
import customerSlice from './features/customer/customerSlice'

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    customer: customerSlice,
  },
})

export default store
