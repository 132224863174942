import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PackingSlip from './pages/packingSlip/PackingSlip'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const ClientLayout = React.lazy(() => import('./layout/ClientLayout'))

/* ---------Dashboard Routes Start------------ */
// Containers
const DashboardLayout = React.lazy(() => import('./layout/DashboardLayout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const Register = React.lazy(() => import('./pages/register/Register'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* <Suspense fallback={loading}> */}
        <Suspense>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register/:userType" name="Register Page" element={<Register />} />
            <Route exact path="/packing-slip" name="Packing Slip Page" element={<PackingSlip />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/admin/*" element={<DashboardLayout />} />
            <Route path="*" element={<ClientLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
